<template>
    <b-row>
        <b-col
            md="3"
            v-for="item in items"
            :key="item.title"
        >
            <b-card
                :text-variant="phase == item.title ? 'light' : 'dark '"
                :bg-variant="phase == item.title ? 'primary' : 'light'"
            >
                <b-card-title
                    :class="`mb-1 text-${phase == item.title ? 'light' : 'dark'}`"
                >
                    {{ item.title }}
                </b-card-title>
                <b-card-text>
                    {{ item.description }}
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
  },
  data() {
    return {
      items: [
        { title: 'Assessment', description: 'Identify your risks to understand what are the security vectors you expoused.' },
        { title: 'Strategy', description: 'Build a security strategy which fits to the bussines needs based on the risk we found.' },
        { title: 'Execution', description: 'Implement the strategy and solutions, getting your business to A++ security.' },
        { title: 'Continuity', description: 'Continuously monitoring and  ensuring your business is free of vulnerabilities and risks.' },
      ],
    }
  },
  props: {
    phase: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
